import React, { useEffect, useState } from 'react';
import { Field, FormElement, Form } from '@progress/kendo-react-form';
import { requiredValidator } from '../validators';
import { FormRadioGroup } from '../form-components';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FormInput, DropDown, DatePic } from "../form-components";
import { FormDatePicker, FormTextArea, FormDropDownList } from "../../SchoolInfoWizard/form-components";
import { Stepper } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import $ from "jquery";
import moment from 'moment';
import { TextArea, Input, Switch } from '@progress/kendo-react-inputs';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import PaperOnlyPdf from '../OrderFormPDFS/2023 School Delivery Kit Components Order Form.pdf'
import PaperAndOnlinePdf from '../OrderFormPDFS/2023 School Delivery Order Form.pdf'
import OnlinePdf from '../OrderFormPDFS/Online Ordering Only.pdf'
import InfoIcon from '@mui/icons-material/Info';



const OrderForms = (props) => {
    const data = [
        {
            label: 'Yes',
            value: true,
            style: { borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }
        },
        {
            label: 'No',
            value: false,
            style: { borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }
        }
    ];
    const OptionchangeEvent = (e) => {
        props.FlyerOptionChange(e.value);
    };

    return (
        <div>
            <label className="fieldlabel">
                Do you want printed flyers/order forms?
            </label>
            <Field
                data={data}
                key={'tt_ReceiveDidYouForgetflyer'} id={'tt_ReceiveDidYouForgetflyer'}
                name={'tt_ReceiveDidYouForgetflyer'} layout={'vertical'} component={FormRadioGroup}
                onChange={OptionchangeEvent}
                defaultValue={props.OrderFormProofformState.tt_ReceiveDidYouForgetflyer} />
        </div>);
};

const KitOption = (props) => {

    const optiondata = [
        {
            label: <label className="radiofieldlabel">Kit Components
                <span title="This option will show kit components for each grade level kit and you will received a unique form for each grade to be distributed."><InfoIcon style={{ "cursor": "pointer" }} /></span>
                <a style={{ textDecoration: "underline", marginLeft: "15px !important" }} href={PaperOnlyPdf} target="_blank">See kit PDF</a>
            </label>,
            value: 'KitComponents',
            style: { borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }
        },
        {
            label: <label className="radiofieldlabel">School: No Kit Components
                <span title="This option will not show any kit components. This version will show all grades offered at the school on one flyer/order form."><InfoIcon style={{ "cursor": "pointer" }} /></span>
                <a style={{ textDecoration: "underline", marginLeft: "10px !important" }} href={PaperAndOnlinePdf} target="_blank">Single Page PDF</a>
            </label>,
            value: 'ComponentsCount',
            style: { borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }
        }
    ];

    return (
        <div style={{ marginTop: "3.5rem" }}>
            <label className="fieldlabel">
                Pick an flyer/order form option:
            </label>
            <div style={{ display: "flex" }}>
                <Field
                    data={optiondata}
                    key={'tt_orderformtype'} id={'tt_orderformtype'}
                    name={'tt_orderformtype'} layout={'vertical'}
                    component={FormRadioGroup}
                    onChange={props.KitOptionChange}
                    defaultValue={props.OrderFormProofformState.tt_orderformtype}
                />
            </div>
        </div>
    );
};

const ElectronicCopy = (props) => {
    const electronicCopydata = [
        {
            label: 'Yes',
            value: true,
            style: { borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }
        },
        {
            label: 'No',
            value: false,
            style: { borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }
        }
    ];

    return (
        <div style={{ marginTop: props.OrderFormProofformState.tt_ReceiveDidYouForgetflyer ? "6.9rem" : "3.5rem" }}>
            <label className="fieldlabel">
                Do you want a PDF copy of the flyers/order form emailed to you?
            </label>
            <Field key={'tt_ReceiveElectronicFlyer'} id={'tt_ReceiveElectronicFlyer'}
                name={'tt_ReceiveElectronicFlyer'} layout={'vertical'} component={FormRadioGroup}
                defaultValue={props.OrderFormProofformState.tt_ReceiveElectronicFlyer}
                data={electronicCopydata}
            />
        </div>);
};
// style="margin-top: 3.5rem;"
const ParentNotes = (props) => {
    return (<div style={{ marginTop: "8.6rem" }}>
        <br />
        <div className="col-12 col-md-12">
            <label className="fieldlabel">Your enrollment : {props.OrderFormProofformState.enrollment}</label>
            <span title="Please contact your coordinator if the enrollment number is incorrect. We will print an extra 3% to have as extra."><InfoIcon style={{ "cursor": "pointer" }} /></span>
        </div>
        <div className="col-12 col-md-12">
            <label className="fieldlabel">Parent Notes / Website Notes</label>

            <Field key={'parentNotes'} id={'parentNotes'}
                name={'parentNotes'} layout={'horizontal'} component={FormTextArea} max={350}
                hint={"Hint: Enter your text here"}
                value={props.formRenderProps.valueGetter("parentNotes")}
                defaultValue={props.OrderFormProofformState.parentNotes}
            />
        </div>
    </div>);
};

const OrderFormcountsGrid = (props) => {
    const [griddata, setgriddata] = React.useState([]);
    useEffect(() => {
        if (!props.OrderFormProofformState.ubsSchoolGradesOutModel) {
            props.OrderFormProofformState.ubsSchoolGradesOutModel = [];
            return;
        }
        props.OrderFormProofformState.ubsSchoolGradesOutModel = props.OrderFormProofformState.ubsSchoolGradesOutModel.map((item, index) => {
            return Object.assign(
                {
                    inEdit: true
                },
                item.OrderFormID = index,
                item
            )
        });
        setgriddata(props.OrderFormProofformState.ubsSchoolGradesOutModel);
    }, []);

    const OrderFormsitemChange = (event) => {
        const field = event.field || "";
        const newData = griddata.map((item) => {
            return item.OrderFormID === event.dataItem.OrderFormID
                ? {
                    ...item, [field]: event.value
                }
                : item
        });
        setgriddata(newData);
        props.OrderFormProofformState.ubsSchoolGradesOutModel = newData;
    };
    //#region "     Custom Cells    "

    const EditParentNotes = ({ dataItem }) => {
        const [orderNote, setOrderNote] = React.useState(dataItem.orderNote);
        const OnOrderNoteChangeEvent = (event) => {
            setOrderNote(event.target.value);
            dataItem.orderNote = event.target.value;
        }
        return (
            <td colSpan="1" role="gridcell" aria-colindex="7" aria-selected="false" data-grid-col-index="6">
                <input type="text" placeholder="Enter notes..." value={orderNote} onChange={OnOrderNoteChangeEvent} />
            </td>
        );
    };
    //#endregion

    return (<div>
        <h5 className="wizardStepHeadLabel">
        Flyer/Order Form Counts
        </h5>
        <label className="fieldlabel">
            Your enrollment : {props.OrderFormProofformState.enrollment}
            <br />When selecting quantities of Flyers/Order Forms, please make sure the total does not exceed 3% of your total enrollment.
            <br />If you are requesting quantities exceeding 3% of your enrollment, please contact your representative for approval to process the order.
            <br />You can change this parent note by modifying the text below on each grade line.
        </label>
        <div>
            <Grid id="OrderFormCountsGridID" data={griddata} editField="inEdit" scrollable="scrollable" resizable={true}
                dataItemKey={"OrderFormID"} onItemChange={OrderFormsitemChange}
                style={{ height: "320px" }}>
                <Column field="grade" title="Grade" width="120" editable={false} />
                <Column field="type" title="Type" width="120" editable={false} />
                <Column field="itemdesc" title="Kit Description" width="150" editable={false} />
                <Column field="quantity" title="Quantity to Print" format="{0:c2}" editor="numeric" width={150} />
                <Column field="orderNote" title="Important Parent Notes" cell={EditParentNotes} width="400" />
            </Grid>
        </div>
    </div>);
};


const OrderFormsStock = (props) => {
    const orderFormsStockdata = [
        {
            label: 'Yes',
            value: true,
            style: { borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }
        },
        {
            label: 'No',
            value: false,
            style: { borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }
        }
    ];

    return (
        <div style={{ marginTop: "12rem" }}>
            <br />
            <label className="fieldlabel">
                Would you like to receive our standard "Did You Forget" flyer to provide parents who have not placed an order?
            </label>
            <Field key={'forgetflyer'} id={'forgetflyer'}
                name={'forgetflyer'} layout={'vertical'} component={FormRadioGroup}
                defaultValue={props.OrderFormProofformState.forgetflyer}
                data={orderFormsStockdata}
            />
        </div>);
};

const OrderFormShippingAddress = (props) => {
    const [shipStatedropdown, setshipStatedropdown] = React.useState(props.OrderFormProofformState.stateProvinceListModel);
    const shipToDropDownList = [{ tt_ShipTo: 206340000, text: "School" }, { tt_ShipTo: 206340001, text: "Residence" }];

    useEffect(() => {
        if (typeof (props.OrderFormProofformState.tt_ShipTo) != "object")
            props.OrderFormProofformState.tt_ShipTo = props.OrderFormProofformState.tt_ShipTo == 206340001 ? shipToDropDownList[1] : shipToDropDownList[0];

        if (props.OrderFormProofformState.tt_StateId && props.OrderFormProofformState.tt_StateId.length > 0 && shipStatedropdown.length > 0) {
            props.OrderFormProofformState.tt_StateId = shipStatedropdown.filter(function (each) { return each.state_province_id == props.OrderFormProofformState.tt_StateId })[0];
        }
    }, []);
    const onShipOptionchangeEvent = (event) => {
        //log("change", event.target.value);
        if (event.value.text == "Residence") {
            props.OrderFormProofformState.tt_FirstName = "";
            props.OrderFormProofformState.tt_LastName = "";
            props.OrderFormProofformState.tt_Title = "";
            props.OrderFormProofformState.tt_Address_line1 = "";
            props.OrderFormProofformState.tt_address_line2 = "";
            props.OrderFormProofformState.tt_City = "";
            props.OrderFormProofformState.tt_PostalCode = "";
            props.OrderFormProofformState.tt_StateId = "";
        }
        else {
            //props.orderformschooladdress
            props.OrderFormProofformState.tt_FirstName = props.orderformschooladdress.tt_FirstName;
            props.OrderFormProofformState.tt_LastName = props.orderformschooladdress.tt_LastName;
            props.OrderFormProofformState.tt_Title = props.orderformschooladdress.tt_Title;
            props.OrderFormProofformState.tt_Address_line1 = props.orderformschooladdress.tt_Address_line1;
            props.OrderFormProofformState.tt_address_line2 = props.orderformschooladdress.tt_address_line2;
            props.OrderFormProofformState.tt_City = props.orderformschooladdress.tt_City;
            props.OrderFormProofformState.tt_PostalCode = props.orderformschooladdress.tt_PostalCode;

            if (props.orderformschooladdress.tt_StateId && props.orderformschooladdress.tt_StateId.length > 0 && shipStatedropdown.length > 0) {
                props.OrderFormProofformState.tt_StateId = shipStatedropdown.filter(function (each) { return each.state_province_id == props.orderformschooladdress.tt_StateId })[0];
            }

        }
    };
    return (
        <div>
            <label className="wizardStepHeadLabel">
                Shipping Address
            </label>
            <br />
            <label className="fieldlabel">Please consider school closings when requesting your flyers/order forms to be shipped to the school.</label>
            <fieldset className="k-form-fieldset grid md:grid-cols-2 gap-2" style={{ margin: "0px" }}>
                <div >
                    <label className="fieldlabel">Ship to option</label>
                    <Field name={"tt_ShipTo"} component={FormDropDownList}
                        defaultValue={shipToDropDownList[0]}
                        validator={requiredValidator}
                        textField="text" dataItemKey="tt_ShipTo" data={shipToDropDownList}
                        onChange={onShipOptionchangeEvent}
                    />
                </div>
                <div >
                    <label className="fieldlabel">First name</label>
                    <Field name={"tt_FirstName"}
                        validator={requiredValidator}
                        defaultValue={props.OrderFormProofformState.tt_FirstName}
                        component={FormInput} />
                </div>
                <div >
                    <label className="fieldlabel">Last name</label>
                    <Field name={"tt_LastName"}
                        validator={requiredValidator}
                        defaultValue={props.OrderFormProofformState.tt_LastName}
                        component={FormInput} />
                </div>
                <div >
                    <label className="fieldlabel">Organization</label>
                    <Field name={"tt_Title"}
                        defaultValue={props.OrderFormProofformState.tt_Title}
                        component={FormInput} />
                </div>
                <div >
                    <label className="fieldlabel">Address line 1</label>
                    <Field name={"tt_Address_line1"}
                        validator={requiredValidator}
                        defaultValue={props.OrderFormProofformState.tt_Address_line1}
                        component={FormInput} />
                </div>
                <div >
                    <label className="fieldlabel">Address line 2</label>
                    <Field name={"tt_address_line2"}
                        defaultValue={props.OrderFormProofformState.tt_address_line2}
                        component={FormInput} />
                </div>
                <div >
                    <label className="fieldlabel">City</label>
                    <Field name={"tt_City"}
                        validator={requiredValidator}
                        defaultValue={props.OrderFormProofformState.tt_City}
                        component={FormInput} />
                </div>
                <div >
                    <label className="fieldlabel">State</label>
                    <Field name={"tt_StateId"}
                        validator={requiredValidator}
                        defaultValue={props.OrderFormProofformState.tt_StateId}
                        component={FormDropDownList}
                        textField="state_province_name" dataItemKey="state_province_id" data={shipStatedropdown} />
                </div>
                <div >
                    <label className="fieldlabel">Zip code</label>
                    <Field name={"tt_PostalCode"}
                        validator={requiredValidator}
                        defaultValue={props.OrderFormProofformState.tt_PostalCode}
                        component={FormInput} />
                </div>
            </fieldset>
        </div>
    );
};

const OrderFormProof = (props) => {

    const [OrderFormProofformState, setOrderFormProofformState] = React.useState(props.formState.orderingInfoOutModel ? props.formState.orderingInfoOutModel : {});
    const [OrderFormProofstep, setOrderFormProofstep] = React.useState(0);

    const stepOrderFormProofPages = [OrderForms, KitOption, ElectronicCopy, ParentNotes, OrderFormcountsGrid, OrderFormsStock, OrderFormShippingAddress];

    let numberOfSteps = [
        {
            label: 'Flyers',
            isValid: undefined,
            key: 1
        }, {
            label: 'Kit Option',
            isValid: undefined,
            key: 2
        }, {
            label: 'Electronic Copy',
            isValid: undefined,
            key: 3
        }, {
            label: 'Parent Notes',
            isValid: undefined,
            key: 4
        }, {
            label: 'Counts',
            isValid: undefined,
            key: 5
        }, {
            label: 'Stock',
            isValid: undefined,
            key: 6
        }, {
            label: 'Shipping Address',
            isValid: undefined,
            key: 7
        }];
    useEffect(() => {
        FlyerOptionChange(OrderFormProofformState.tt_ReceiveDidYouForgetflyer);
    }, []);

    const [OrderFormProofsteps, setOrderFormProofsteps] = React.useState(numberOfSteps);

    var OrderFormProoflastStepIndex = OrderFormProofsteps.length - 1;
    var isOrderFormProofLastStep = OrderFormProoflastStepIndex === OrderFormProofstep;
    const onOrderFormProofStepSubmit = React.useCallback(event => {
        const {
            isValid,
            values
        } = event;

        const currentSteps = OrderFormProofsteps.map((currentStep, index) => ({
            ...currentStep,
            isValid: index === OrderFormProofstep ? isValid : currentStep.isValid
        }));

        setOrderFormProofsteps(currentSteps);
        if (!isValid) {
            props.formState.orderingInfoOutModel.isValid = isValid;
            return;
        }

        setOrderFormProofstep(() => Math.min(OrderFormProofstep + 1, OrderFormProoflastStepIndex));
        setOrderFormProofformState(values);

        if (isOrderFormProofLastStep) {
            props.formState.orderingInfoOutModel = values;
            props.formState.orderingInfoOutModel.isValid = true;
            $("#btnparentSubmit").click();
        }
    }, [stepOrderFormProofPages, isOrderFormProofLastStep, OrderFormProofstep, OrderFormProoflastStepIndex]);

    const onOrderFormProofPrevClick = React.useCallback(event => {
        event.preventDefault();
        if (OrderFormProofstep === 0) {
            $("#btnProgramWizardPrevID").click();
        }
        else {
            setOrderFormProofstep(() => Math.max(OrderFormProofstep - 1, 0));
        }

    }, [OrderFormProofstep, setOrderFormProofstep]);

    const handleChangeOrderFormProof = (e) => {
        setOrderFormProofstep(e.value);
    };

    const FlyerOptionChange = (isFlyerEnabled, tempOrderFormProofformState = OrderFormProofformState) => {
        if (isFlyerEnabled) {
            if (tempOrderFormProofformState.tt_orderformtype === "KitComponents") {//Show Count and hide parent notes
                setOrderFormProofsteps([numberOfSteps[0], numberOfSteps[1], numberOfSteps[2], numberOfSteps[4], numberOfSteps[5], numberOfSteps[6]]);
            }
            else if (tempOrderFormProofformState.tt_orderformtype === "ComponentsCount") {//Show parent notes and hide Count
                setOrderFormProofsteps([numberOfSteps[0], numberOfSteps[1], numberOfSteps[2], numberOfSteps[3], numberOfSteps[5], numberOfSteps[6]]);
            }
            else {//Show All
                setOrderFormProofsteps([numberOfSteps[0], numberOfSteps[1], numberOfSteps[2], numberOfSteps[3], numberOfSteps[4], numberOfSteps[5], numberOfSteps[6]]);
            }
        }
        else {//Show parent notes
            setOrderFormProofsteps([numberOfSteps[0], numberOfSteps[2], numberOfSteps[3]]);
        }
        OrderFormProoflastStepIndex = OrderFormProofsteps.length - 1;
        isOrderFormProofLastStep = OrderFormProoflastStepIndex === OrderFormProofstep;
    };

    const KitOptionChange = (e) => {
        let temp = { ...OrderFormProofformState };
        temp.tt_orderformtype = e.value;
        setOrderFormProofformState(temp);
        FlyerOptionChange(OrderFormProofformState.tt_ReceiveDidYouForgetflyer, temp);
    };
    //className="mt-auto pb-4" style={{ width: "-webkit-fill-available" }}
    return <>
        <br />
        <label className="wizardStepHeadLabel">
            Flyers/Order Forms
        </label>
        <div style={{
            display: 'flex'
        }}>
            <Stepper value={OrderFormProofstep} onChange={handleChangeOrderFormProof} key={OrderFormProofstep}
                items={OrderFormProofsteps} style={{ width: "15%" }} className="mb-auto"
                orientation={"vertical"} />
            <div style={{ width: "70%" }}>
                <Form initialValues={OrderFormProofformState} onSubmitClick={onOrderFormProofStepSubmit} render={formRenderProps =>
                    <div>
                        <FormElement className='ml-10 mb-auto'>
                            {
                                (OrderFormProofsteps[OrderFormProofstep].label === "Flyers" && <OrderForms FlyerOptionChange={FlyerOptionChange} OrderFormProofformState={OrderFormProofformState} />) ||
                                (OrderFormProofsteps[OrderFormProofstep].label === "Kit Option" && <KitOption KitOptionChange={KitOptionChange} OrderFormProofformState={OrderFormProofformState} />) ||
                                (OrderFormProofsteps[OrderFormProofstep].label === "Electronic Copy" && <ElectronicCopy OrderFormProofformState={OrderFormProofformState} />) ||
                                (OrderFormProofsteps[OrderFormProofstep].label === "Parent Notes" && <ParentNotes formRenderProps={formRenderProps} OrderFormProofformState={OrderFormProofformState} />) ||
                                (OrderFormProofsteps[OrderFormProofstep].label === "Counts" && <OrderFormcountsGrid OrderFormProofformState={OrderFormProofformState} />) ||
                                (OrderFormProofsteps[OrderFormProofstep].label === "Stock" && <OrderFormsStock OrderFormProofformState={OrderFormProofformState} />) ||
                                (OrderFormProofsteps[OrderFormProofstep].label === "Shipping Address" && <OrderFormShippingAddress OrderFormProofformState={OrderFormProofformState} programdata={props.programdata} orderformschooladdress={props.orderformschooladdress} />)
                            }
                            <span style={{ marginTop: '40px' }} className={'k-form-separator'} />

                            {OrderFormProofsteps[OrderFormProofstep].label === "Counts" && <div id="OrderFormfooterIDCounts" style={{ width: '-webkit-fill-available' }}
                                className='ProgramScheduleSubWizardFooter'>
                                <div>
                                    <Button id="ScheduledPrev" className="step-prev-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                        onClick={onOrderFormProofPrevClick}>Previous
                                    </Button>
                                    <span className="step-text-class">Step {OrderFormProofstep + 1} of {stepOrderFormProofPages.length}</span>
                                    <Button id="ScheduledNext" className="step-prev-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                        onClick={formRenderProps.onSubmit}>
                                        {isOrderFormProofLastStep ? 'Next Step' : 'Next'}
                                    </Button>
                                </div>
                            </div>}

                            {OrderFormProofsteps[OrderFormProofstep].label != "Counts" && <div id="OrderFormfooterID" style={{ width: '-webkit-fill-available' }}
                                className='ProgramScheduleSubWizardFooter'>
                                <div>
                                    <Button id="ScheduledPrev" className="step-prev-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                        onClick={onOrderFormProofPrevClick}>Previous
                                    </Button>
                                    <span className="step-text-class">Step {OrderFormProofstep + 1} of {stepOrderFormProofPages.length}</span>
                                    <Button id="ScheduledNext" className="step-prev-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                        onClick={formRenderProps.onSubmit}>
                                        {isOrderFormProofLastStep ? 'Next Step' : 'Next'}
                                    </Button>
                                </div>
                            </div>}
                        </FormElement>
                    </div>
                } />
            </div>
        </div>
    </>;
};
export default OrderFormProof;