import React, { useEffect, useState } from 'react';
import { Field, FormElement, Form } from '@progress/kendo-react-form';
import { requiredValidator } from '../validators';
import { FormInput, DropDown, DatePic } from "../form-components";
import { FormDatePicker } from "../../SchoolInfoWizard/form-components";
import { Stepper } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import $ from "jquery";
import InfoIcon from '@mui/icons-material/Info';
import DeliveryDatesPdf from '../OrderFormPDFS/Delivery Dates and Deadlines.pdf'


const DeliveryWeek = (props) => {
    const [dropdownData, setdropdownData] = React.useState(props.dropdownData);
    return (
        <>
            {
                !props.programDeliveryOutModel.homeDelivery
                    ? <label className="fieldlabel">Choose a delivery WEEK. Select a week that is at least 1 week BEFORE the 1st day of school.  We recommend you choose the week prior to the start of school.
                        All orders are guaranteed to arrive within the delivery week you specify &minus; or you get them FREE*.*See terms and conditions at SchoolSpecialty.com/schoolkidz-delivery-guarantee.
                        <a href={DeliveryDatesPdf} target="_blank">Dates and Deadlines form </a><sup><font color="red"> *</font></sup>
                    </label>
                    : <label className="fieldlabel">Choose a delivery week <sup><font color="red"> *</font></sup></label>
            }
            <div style={{ width: "200px" }}>

                <Field
                    key={"deliveryWeek"}
                    id={"deliveryWeek"}
                    name={"deliveryWeek"}
                    data={dropdownData}
                    onChange={(e) => { e.preventDefault(); }}
                    component={DropDownList}
                />
                <br />
            </div>
        </>
    );
};


const StartDate = (props) => {

    return <div style={{ marginTop: "3.3rem" }}>
        {
            props.programDeliveryOutModel.orderType == "OnlineOnly" || (props.programDeliveryOutModel.orderType == "OnlineAndPaper" && !props.programDeliveryOutModel.homeDelivery)
                ? <label className="fieldlabel">Date your sale will start. Parents will go to shopttkits.com to place their orders. <sup><font color="red">*</font></sup></label>
                : <><label className="fieldlabel">Date to begin your sale and distribute the flyers/order forms to classroooms/parents/students<sup><font color="red">*</font></sup></label>
                    <span title="Online Ordering www.shopttkits.com begins on this date"><InfoIcon style={{ "cursor": "pointer" }} /></span></>
        }
        <Field key={'takeHomeDate'}
            className="ProgramScheduleSeparation"
            id={'takeHomeDate'}
            name={"takeHomeDate"}
            layout={'horizontal'}
            component={DatePic}
            validator={requiredValidator} />

    </div>;
}

const EndDate = (props) => {
    const maxRestrictEndDate = new Date('06/23/2024');//This date is hardwired as per client request(Jenelle)
    var marginTopStyle = "6.7rem";
    if (props.scheduleformState.orderDueDate && props.scheduleformState.orderDueDate > maxRestrictEndDate) {
        props.scheduleformState.orderDueDate = maxRestrictEndDate;
    }
    if (props.scheduleformState.orderType === 'PaperOnly') {
        marginTopStyle = "3.3rem"
    }
    else if (props.scheduleformState.orderType === 'OnlineAndPaper') {
        marginTopStyle = "10rem"
    }
    return (
        <div style={{ marginTop: marginTopStyle }}>
            {
                props.programDeliveryOutModel.orderType == "OnlineOnly" && !props.programDeliveryOutModel.homeDelivery
                    ? <label className="fieldlabel"><label className="fieldlabel">Date the website will disable, and parents will not be able to order.</label> <sup><font color="red">*</font></sup></label>
                    : (props.programDeliveryOutModel.orderType == "OnlineAndPaper" && !props.programDeliveryOutModel.homeDelivery)
                        ? <label className="fieldlabel">Date the website will disable, and parents will not be able to order.  <sup><font color="red">*</font></sup></label>
                        : (props.programDeliveryOutModel.orderType == "PaperOnly" && !props.programDeliveryOutModel.homeDelivery)
                            ? <label className="fieldlabel">Date the order is due to SchoolKidz.If placing a school order, this will be the date we need your order/tally sheet.  <sup><font color="red">*</font></sup></label>
                            : <label className="fieldlabel">Date after which no orders will be accepted<sup><font color="red">*</font></sup></label>
            }

            <Field key={'orderDueDate'}
                className="ProgramScheduleSeparation"
                id={'orderDueDate'} name={"orderDueDate"} max={maxRestrictEndDate} layout={'horizontal'} component={DatePic} validator={requiredValidator} />
        </div>);
};

const Formdetails = (props) => {

    return (<div style={{ marginTop: props.scheduleformState.orderType === 'OnlineOnly' ? "10rem" : "13.5rem" }}>
        {
            (props.programDeliveryOutModel.orderType == "OnlineOnly" && !props.programDeliveryOutModel.homeDelivery) || (props.programDeliveryOutModel.orderType == "OnlineAndPaper" && !props.programDeliveryOutModel.homeDelivery)
                ? <label className="fieldlabel">Date printed on flyers/order forms to notify parents when to pick up kits from school. This date can not be the same week as your delivery. If an event, such as Registration, you can enter &quot;School Registration Day&quot;. Or, if on desks for the first day of school, enter &quot;On desk first day of school&quot;. <sup><font color="red">*</font></sup></label>
                : <><label className="fieldlabel">Date or Event to be printed on Flyers/Order Forms<sup><font color="red">*</font></sup></label>
                    <span title="This lets parents know when to pick up their kits. Unsure of event date? Just put in the Event Name eg.August 22nd"><InfoIcon style={{ "cursor": "pointer" }} /></span>
                </>
        }
        <Field key={'pickupDate'}
            className="ProgramScheduleSeparation"
            id={'pickupDate'} name={"pickupDate"} layout={'horizontal'} maxlength="40" component={FormInput} validator={requiredValidator} />
    </div>);
};

const ReturnDeadline = (props) => {
    const maxRestrictDate = new Date('06/23/2024');//This date is hardwired as per client request(Jenelle)
    if (props.scheduleformState.returnByDate && props.scheduleformState.returnByDate > maxRestrictDate) {
        props.scheduleformState.returnByDate = maxRestrictDate;
    }
    return (<div style={{ marginTop: "6.7rem" }}>
        {
            props.programDeliveryOutModel.orderType == "OnlineAndPaper" && !props.programDeliveryOutModel.homeDelivery
                ? <label className="fieldlabel">Date after which no paper order will be accepted.This date will print on flyers/order forms. <sup><font color="red">*</font></sup></label>
                :
                <>
                    <label className="fieldlabel">Order return deadline for parents<sup><font color="red">*</font></sup></label>
                    <span title="This date gets printed on flyers/order forms. Pro Tip: Allow 2-3 Weeks from the date of sale/order form distribution for parents to order"><InfoIcon style={{ "cursor": "pointer" }} /></span>
                </>
        }
        <Field key={'returnByDate'} id={'returnByDate'} name={"returnByDate"} layout={'horizontal'}
            className="ProgramScheduleSeparation"
            component={DatePic} max={maxRestrictDate} validator={requiredValidator}
            value={props.scheduleformState.returnByDate} />
    </div>);
};

const CheckDetails = (props) => {


    return <div style={{ marginTop: "17rem" }}>
        {props.programDeliveryOutModel.orderType == "OnlineAndPaper" && !props.programDeliveryOutModel.homeDelivery
            ? <label className="fieldlabel">Parents should make checks payable to your organization (cannot be payable to SchoolKidz).<sup><font color="red">*</font></sup></label>
            : <><label className="fieldlabel">Parents should make the checks payable to:<sup><font color="red">*</font></sup></label>
                <span title="Checks cannot be made to lorem ipsum"><InfoIcon style={{ "cursor": "pointer" }} /></span></>
        }
        <Field key={'payableTo'}
            className="ProgramScheduleSeparation"
            id={'payableTo'} name={"payableTo"} maxlength="30" layout={'horizontal'} component={FormInput} validator={requiredValidator} />
    </div>
}

const ChooseSchedule = (props) => {
    const [scheduleformState, setScheduleformState] = React.useState(props.formState.programDeliveryOutModel ? props.formState.programDeliveryOutModel : {});
    const [Schedulestep, setSchedulestep] = React.useState(0);
    const stepSchedulePages = [DeliveryWeek];
    let numberOfSteps = [{
        label: 'Delivery Week',
        isValid: undefined,
        key: 1
    }];

    if (props.formState.programDeliveryOutModel.orderType === 'OnlineOnly') {
        numberOfSteps.push({
            label: 'Online Start Date',
            isValid: undefined,
            key: 2
        });
        stepSchedulePages.push(StartDate);
    }
    else if (props.formState.programDeliveryOutModel.orderType === 'OnlineAndPaper') {
        numberOfSteps.push({
            label: 'Online Sale Start Date',
            isValid: undefined,
            key: 2
        });
        stepSchedulePages.push(StartDate);
    }

    //End Date Logic
    if (props.formState.programDeliveryOutModel.orderType === 'OnlineOnly') {
        numberOfSteps.push({
            label: 'Online End Date',
            isEndDate: true,
            isValid: undefined,
            key: 3
        });
        stepSchedulePages.push(EndDate);
    }
    else if (props.formState.programDeliveryOutModel.orderType === 'OnlineAndPaper') {
        numberOfSteps.push({
            label: 'Paper End Date',
            isValid: undefined,
            isEndDate: false,
            key: 3
        });
        stepSchedulePages.push(ReturnDeadline);
    }
    else if (props.formState.programDeliveryOutModel.orderType === 'PaperOnly') {
        numberOfSteps.push({
            label: 'Order Due Date',
            isEndDate: true,
            isValid: undefined,
            key: 3
        });
        stepSchedulePages.push(EndDate);
    }

    if (!props.formState.programDeliveryOutModel.homeDelivery && props.formState.programDeliveryOutModel.orderType === 'OnlineAndPaper') {
        numberOfSteps.push({
            label: 'Online End Date',
            isValid: undefined,
            isEndDate: true,
            key: 4
        });
        stepSchedulePages.push(EndDate);
    }
    if (!props.formState.programDeliveryOutModel.homeDelivery && (props.formState.programDeliveryOutModel.orderType === 'OnlineOnly' || props.formState.programDeliveryOutModel.orderType === 'OnlineAndPaper')) {
        numberOfSteps.push({
            label: 'Parent Pick-up Date',
            isValid: undefined,
            key: 5
        });
        stepSchedulePages.push(Formdetails);
    }

    if (!props.formState.programDeliveryOutModel.homeDelivery && props.formState.programDeliveryOutModel.orderType === 'OnlineAndPaper') {
        numberOfSteps.push({
            label: 'Checks Payable to',
            isValid: undefined,
            key: 6
        });

        stepSchedulePages.push(CheckDetails);
    }

    const [scheduledsteps, setScheduledsteps] = React.useState(numberOfSteps);

    const schedulelastStepIndex = scheduledsteps.length - 1;
    const isscheduleLastStep = schedulelastStepIndex === Schedulestep;
    const onScheduleStepSubmit = React.useCallback(event => {
        const {
            isValid,
            values
        } = event;

        const currentSteps = scheduledsteps.map((currentStep, index) => ({
            ...currentStep,
            isValid: index === Schedulestep ? isValid : currentStep.isValid
        }));

        setScheduledsteps(currentSteps);
        if (!isValid) {
            props.formState.programDeliveryOutModel.isValid = isValid;
            return;
        }

        setSchedulestep(() => Math.min(Schedulestep + 1, schedulelastStepIndex));
        setScheduleformState(values);

        if (isscheduleLastStep) {
            props.formState.programDeliveryOutModel = values;
            props.formState.programDeliveryOutModel.isValid = true;
            $("#btnparentSubmit").click();
            //props.onStepSubmit();
        }
    }, [stepSchedulePages, isscheduleLastStep, Schedulestep, schedulelastStepIndex]);

    const onSchedulePrevClick = React.useCallback(event => {
        event.preventDefault();
        if (Schedulestep === 0) {
            $("#btnProgramWizardPrevID").click();
        }
        else {
            setSchedulestep(() => Math.max(Schedulestep - 1, 0));
        }

    }, [Schedulestep, setSchedulestep]);

    const handleChangeChooseSchedule = (e) => {
        setSchedulestep(e.value);
    };

    return <>
        <br />
        <h5 className="wizardStepHeadLabel">
            Please choose a schedule, you will need to click next after each entry to continue through all the required information.
        </h5>
        <div style={{
            display: 'flex',
            overflow: 'scroll !important'
        }}>
            <Stepper value={Schedulestep} onChange={handleChangeChooseSchedule} key={Schedulestep} items={scheduledsteps} style={{ width: "18%" }} className="mb-auto"
                orientation={"vertical"} />
            <div className="mt-2 ml-5" style={{ width: "12% !important" }}>
                <ol className="k-step-list k-step-list-vertical">
                    <li className="k-step k-step-first k-step-focus">
                        <a className="k-step-link" title="Delivery Week" >
                            <span className="k-step-text spantext">{scheduleformState.deliveryWeek}</span>
                        </a>
                    </li>
                    {
                        (props.formState.programDeliveryOutModel.orderType === 'OnlineOnly' || props.formState.programDeliveryOutModel.orderType === 'OnlineAndPaper') &&
                        < li className="k-step k-step-first k-step-focus">
                            <a className="k-step-link" title="Start Date" >
                                <span className="k-step-text spantext">{scheduleformState.takeHomeDate ? scheduleformState.takeHomeDate.toLocaleDateString() : ""}</span>
                            </a>
                        </li>}
                    {
                        (!props.formState.programDeliveryOutModel.homeDelivery && props.formState.programDeliveryOutModel.orderType === 'OnlineAndPaper')
                        && <>
                            <li className="k-step k-step-first k-step-focus">
                                <a className="k-step-link" title="Online End Date" >
                                    <span className="k-step-text spantext">{scheduleformState.returnByDate ? scheduleformState.returnByDate?.toLocaleDateString() : ""}</span>
                                </a>
                            </li>

                        </>
                    }
                    <li className="k-step k-step-first k-step-focus">
                        <a className="k-step-link" title="End Date" >
                            <span className="k-step-text spantext">{scheduleformState.orderDueDate ? scheduleformState.orderDueDate.toLocaleDateString() : ""}</span>
                        </a>
                    </li>
                    {
                        (!props.formState.programDeliveryOutModel.homeDelivery && (props.formState.programDeliveryOutModel.orderType === 'OnlineOnly' || props.formState.programDeliveryOutModel.orderType === 'OnlineAndPaper')) &&
                        <li className="k-step k-step-first k-step-focus">
                            <a className="k-step-link" title="Parent Pick-up Date" >
                                <span className="k-step-text spantext">{scheduleformState.pickupDate}</span>
                            </a>
                        </li>
                    }
                    {
                        (!props.formState.programDeliveryOutModel.homeDelivery && props.formState.programDeliveryOutModel.orderType === 'OnlineAndPaper')
                        && <>
                            <li className="k-step k-step-first k-step-focus">
                                <a className="k-step-link" title="Checks Payable to" >
                                    <span className="k-step-text spantext">{scheduleformState.payableTo}</span>
                                </a>
                            </li>
                        </>
                    }

                </ol>
            </div>
            <div style={{ width: "60%" }} className="ml-5">
                <Form initialValues={scheduleformState} onSubmitClick={onScheduleStepSubmit} render={formRenderProps =>
                    <div>
                        <FormElement>
                            {(scheduledsteps[Schedulestep].label === "Delivery Week" && <DeliveryWeek dropdownData={props.formState.deliveryWeekOutModel} scheduleformState={scheduleformState} programDeliveryOutModel={props.formState.programDeliveryOutModel} />) ||
                                (scheduledsteps[Schedulestep].label === "Online Sale Start Date" && <StartDate programDeliveryOutModel={props.formState.programDeliveryOutModel} />) ||
                                (scheduledsteps[Schedulestep].label === "Online Start Date" && <StartDate programDeliveryOutModel={props.formState.programDeliveryOutModel} />) ||
                                (scheduledsteps[Schedulestep].isEndDate === true && <EndDate scheduleformState={scheduleformState} programDeliveryOutModel={props.formState.programDeliveryOutModel} />) ||
                                (!scheduledsteps[Schedulestep].isEndDate && scheduledsteps[Schedulestep].label === "Paper End Date" && <ReturnDeadline scheduleformState={scheduleformState} programDeliveryOutModel={props.formState.programDeliveryOutModel} />) ||
                                (scheduledsteps[Schedulestep].label === "Parent Pick-up Date" && <Formdetails scheduleformState={scheduleformState} programDeliveryOutModel={props.formState.programDeliveryOutModel} />) ||
                                (scheduledsteps[Schedulestep].label === "Checks Payable to" && <CheckDetails scheduleformState={scheduleformState} programDeliveryOutModel={props.formState.programDeliveryOutModel} />) ||
                                stepSchedulePages[Schedulestep]}

                            <span className="PorgramScheduleFooterSteps k-form-separator" />

                            <div id="ScheduledfooterID">
                                <div>
                                    <Button id="ScheduledPrev"
                                        className="step-prev-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                        onClick={onSchedulePrevClick}>Previous
                                    </Button>
                                    <span className="step-text-class">Step {Schedulestep + 1} of {stepSchedulePages.length}</span>
                                    <Button id="ScheduledNext"
                                        className="step-next-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                        onClick={formRenderProps.onSubmit}>
                                        {isscheduleLastStep ? 'Next Step' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    </div>
                } />
            </div>
        </div>
    </>;
};
export default ChooseSchedule;